import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useMap, MapContainer, Marker, Popup, TileLayer, useMapEvent } from 'react-leaflet';
import L from 'leaflet';
import { showNotice } from '../../../../src/notice/Actions';
import { showMessage } from '../../../../src/message/Actions';
import { showConfirm } from '../../../../src/confirm/Actions';
import MapTiles from '../../../../src/mapTiles/MapTiles';
import TopContractAndSiteSelect from '../../../../src/topContractAndSiteSelect/TopContractAndSiteSelect'
import {
  addTrafficSign, changeTrafficSign, removeTrafficSign,
  addTrafficSigns
} from './Actions';
import { fetch, Socket, paddedNumber, timer, toETRSTM35FIN, integerValue, getRoadData, getRoadCoordinates } from '../utils';
import { fromJS } from 'immutable';
import './TrafficSignPage.css';
import 'leaflet/dist/leaflet.css';

let newCategoryCurrent;

const TrafficSignSelect = props => {
  const ownCategorySelected = props.selectedCategory !== '' && isNaN(props.selectedCategory);

  return (
    <div id='traffic-sign-select-container'>
      <div>
        <label id='label-category-select' htmlFor='input-category-select'>Näytä:</label>
        <select id='input-category-select'
          onChange={props.changeCategory}
          value={props.selectedCategory || ''}>
          <option value={''}>Kaikki</option>
          <option value={1}>Kielto- ja rajoitusmerkit</option>
          <option value={2}>Opastusmerkit</option>
          <option value={3}>Työmaan tarvikkeet</option>
          <option value={4}>Varoitusmerkit</option>
          <option value={5}>Lisäkilvet</option>
          <option value={6}>Määräysmerkit</option>
          <option value={7}>Etuajo-oikeus- ja väistämismerkit</option>
          <option value={8}>Ohjemerkit</option>
          <option value={9}>Tiemerkit</option>
          {
            props.ownCategories.map((category, index) => (
              <option key={category.name} value={category.name}>{category.name}</option>
            ))
          }
        </select>
        <button id='new-category-button' onClick={props.newCategory}>
          Uusi kategoria
        </button>
        {ownCategorySelected ?
          <button id='delete-category-button' onClick={props.deleteCategory}>
            Poista kategoria
          </button>
          : null
        }
      </div>
      <div id='traffic-sign-select'>
        {ownCategorySelected ?
          props.ownCategories.find(category => category.name === props.selectedCategory)
            .trafficSigns.map((types, index) => {
              return (
                <div key={index} className='traffic-sign-collection'>
                  {
                    types.map((type, index) => {
                      return (
                        <img key={type.name} className={'traffic-sign-select-icon traffic-sign-collection-item' +
                          (index > 0 ? ' another' : '')}
                          alt={type.name} src={'images/' + type.icon}
                          onClick={props.newTrafficSign.bind(null, types)} />
                      );
                    })
                  }
                </div>
              );
            })
          :
          props.trafficSignTypes.map(type => {
            if (props.selectedCategory !== '' && type.get('category') !== parseInt(props.selectedCategory, 10)) {
              return null;
            }
            return (
              <img key={type.get('name')} className='traffic-sign-select-icon'
                alt={type.get('name')} src={'images/' + type.get('icon')}
                onClick={props.newTrafficSign.bind(null, type)} />
            );
          })
        }
      </div>
    </div>
  );
}

const TrafficSigns = props => {
  if (!props.dimensionsReady) {
    return null;
  }

  let trafficSigns = [];

  for (let i = 0; i < props.trafficSigns.size; i++) {
    let currentHeight = 0;
    let trafficSignsText = [];

    for (let index = props.trafficSigns.get(i).get('signs').size - 1; index >= 0; index--) {
      const sign = props.trafficSigns.get(i).get('signs').get(index);
      const order = index + 1;
      trafficSignsText.unshift(
        <span key={order}>
          <strong>{order + '. '}</strong> {sign.get('name')}
          <br />
        </span>
      );

      const erected = props.trafficSigns.get(i).get('erected');
      const raised = props.trafficSigns.get(i).get('raised');

      const status = raised != null ? 'raised' : (erected != null ? '' : ' not-erected');
      let className = status;

      if (props.selectedTrafficSign &&
        props.trafficSigns.get(i).get('id') === props.selectedTrafficSign.get('id')) {
        if (props.trafficSigns.get(i).get('signs').size === 1) {
          className += ' selected-traffic-sign';
        }
        else {
          if (index === 0) {
            className += ' selected-traffic-sign-top';
          }
          else if (index === props.trafficSigns.get(i).get('signs').size - 1) {
            className += ' selected-traffic-sign-bottom';
          }
          else {
            className += ' selected-traffic-sign-middle';
          }
        }
      }

      const extraCustomTextClassName = sign.get('customTextColor') != null ? sign.get('customTextColor') : 'black';

      let iconSize = [40, 40];
      let iconAnchor = [20, 40];

      const iconSource = props.getImageIcon(sign.get('name'));

      if (iconSource != null) {
        const dimensions = props.getImageDimensions(iconSource);
        const height = Math.round(dimensions.height * (40 / dimensions.width));
        iconSize = [40, height];
        iconAnchor = [20, Math.round(height + currentHeight)];

        currentHeight += height;
      }

      const icon = new L.divIcon({
        className: 'custom-sign-container',
        iconSize: iconSize,
        iconAnchor: iconAnchor,
        popupAnchor: [0, -currentHeight],
        html: '<img style="width: 40px !important" class="' + className + '" src="images/' +
          iconSource + '"/>' + (sign.get('customText') ?
            '<span class="custom-sign-text ' + sign.get('customText') + ' ' + status + ' ' + extraCustomTextClassName
            + '">' + sign.get('text') + '</span>' : '') + (index === props.trafficSigns.get(i).get('signs').size - 1 ?
              '<div class="' + (props.trafficSigns.get(i).get('both_side') ? 'traffic-sign-position-both-side' : 'traffic-sign-position')
              + '" />' : '')
      });

      const erectedDate = new Date(erected);
      const erectedText = erectedDate.getDate() + '.' + (erectedDate.getMonth() + 1) + '.' + erectedDate.getFullYear()
        + ' ' + paddedNumber(erectedDate.getHours()) + ':' + paddedNumber(erectedDate.getMinutes()) +
        ':' + paddedNumber(erectedDate.getSeconds());
      const raisedDate = new Date(raised);
      const raisedText = raisedDate.getDate() + '.' + (raisedDate.getMonth() + 1) + '.' + raisedDate.getFullYear()
        + ' ' + paddedNumber(raisedDate.getHours()) + ':' + paddedNumber(raisedDate.getMinutes()) +
        ':' + paddedNumber(raisedDate.getSeconds());

      trafficSigns.push(
        <Marker key={props.trafficSigns.get(i).get('id') + ' ' + index}
          position={[props.trafficSigns.get(i).get('latitude'), props.trafficSigns.get(i).get('longitude')]}
          icon={icon}
          draggable={erected == null}
          onClick={props.selectTrafficSign.bind(null, props.trafficSigns.get(i))}
          onDragEnd={props.setTrafficSignAfterDrag.bind(this, props.trafficSigns.get(i))}
          ref={ref => props.goToTrafficSign === props.trafficSigns.get(i).get('id') && setTimeout(() =>
            props.showPopup(ref, [props.trafficSigns.get(i).get('latitude'), props.trafficSigns.get(i).get('longitude')]))}>
          <Popup autoPan={false}>
            <span>
              {trafficSignsText}
              <br />
              {props.trafficSigns.get(i).get('road_number') ?
                <div>
                  <strong>Tie:</strong> {props.trafficSigns.get(i).get('road_number')}
                  <strong> Tieosa:</strong> {props.trafficSigns.get(i).get('road_part')}
                  <strong> Paalu:</strong> {props.trafficSigns.get(i).get('road_distance')}
                </div>
                : null
              }
              <strong> Suunta:</strong> {props.trafficSigns.get(i).get('direction') || '-'}
              <strong> Tien molemmin puolin:</strong> {props.trafficSigns.get(i).get('both_side') ? 'Kyllä' : 'Ei'}
              <br />
              {raised == null ? (
                erected == null ?
                  <div>
                    <button className='popup-button' onClick={props.erectTrafficSign.bind(null, props.trafficSigns.get(i))}>
                      Pystytä
                    </button>
                    <button className='popup-button' onClick={props.moveTrafficSign.bind(null, props.trafficSigns.get(i))}>
                      Siirrä
                    </button>
                  </div>
                  :
                  <div>
                    <strong>Pystytetty: </strong>{erectedText}
                    <br />
                    {props.trafficSigns.get(i).get('image') !== 'null' ?
                      <img className='traffic-sign-image' alt='' src={props.trafficSigns.get(i).get('image')} />
                      :
                      null
                    }
                    <br />
                    <label className='file-upload'>
                      <i className='fa fa-camera'></i>
                      <input type='file' accept={'image/*'} onChange={props.setImageToTrafficSign.bind(this, props.trafficSigns.get(i))} />
                    </label>
                    <button className='popup-button' onClick={props.raiseTrafficSign.bind(null, props.trafficSigns.get(i))}>
                      Poista
                    </button>
                    <button className='popup-button' onClick={props.copyTrafficSign.bind(null, props.trafficSigns.get(i).get('signs'))}>
                      Kopioi
                    </button>
                  </div>
              )
                :
                <div>
                  <strong>Pystytetty: </strong>{erectedText}
                  <br />
                  <strong>Poistettu: </strong>{raisedText}
                  <br />
                  <br />
                  <button className='popup-button' onClick={props.copyTrafficSign.bind(null, props.trafficSigns.get(i).get('signs'))}>
                    Kopioi
                  </button>
                  <button className='popup-button' onClick={props.erectAgainTrafficSign.bind(null, props.trafficSigns.get(i))}>
                    Pystytä uudelleen
                  </button>
                </div>
              }
              <br />
              {erected == null && props.trafficSigns.get(i).get('signs').size > 1 ?
                <button className='popup-button' onClick={props.removeLatestSignType.bind(null, props.trafficSigns.get(i))}>
                  Poista viimeisin merkki
                </button>
                : null
              }
              <button className='delete-button' onClick={props.removeTrafficSign.bind(null, props.trafficSigns.get(i).get('id'))} />
            </span>
          </Popup>
        </Marker>
      );
    }
  }

  return trafficSigns;
}

const NewCategory = props => {
  if (!props.show) return null;

  const ownCategorySelected = props.selectedCategory !== '' && isNaN(props.selectedCategory);

  return (
    <div onClick={props.toggle} className='modal'>
      <div onClick={e => e.stopPropagation()} id='modal-area-new-category'>
        <div className='close' onClick={props.toggle} />
        <h4>Uusi kategoria</h4>
        <label htmlFor='name'>Nimi</label>
        <input id='name' type='text' value={props.newCategoryName || ''}
          onChange={props.changeNewCategoryName.bind(this)} />
        <label id='label-category-select' htmlFor='input-category-select'>Näytä:</label>
        <select id='input-category-select'
          onChange={props.changeCategory}
          value={props.selectedCategory || ''}>
          <option value=''>Kaikki</option>
          <option value={1}>Kielto- ja rajoitusmerkit</option>
          <option value={2}>Opastusmerkit</option>
          <option value={3}>Työmaan tarvikkeet</option>
          <option value={4}>Varoitusmerkit</option>
          <option value={5}>Lisäkilvet</option>
          <option value={6}>Määräysmerkit</option>
          {
            props.ownCategories.map((category, index) => (
              <option key={category.name} value={category.name}>{category.name}</option>
            ))
          }
        </select>
        <button id='toggle-combine-traffic-sign-button'
          className={props.makeCombineTrafficSign ? 'on' : ''}
          onClick={props.toggleMakeCombineTrafficSign}>
          Luo yhdistelmä
        </button>
        <div id='traffic-sign-select-modal'>
          {ownCategorySelected ?
            props.ownCategories.find(category => category.name === props.selectedCategory)
              .trafficSigns.map((types, index) => {
                return (
                  <div key={index} className='traffic-sign-collection'>
                    {
                      types.map((type, index) => {
                        return (
                          <img key={type.name} className={'traffic-sign-select-icon traffic-sign-collection-item' +
                            (index > 0 ? ' another' : '')}
                            alt={type.name} src={'images/' + type.icon}
                            onClick={!props.makeCombineTrafficSign ?
                              props.addTrafficSignToCategory.bind(null, types)
                              : props.combineTrafficSignInCategory.bind(null, types)} />
                        );
                      })
                    }
                  </div>
                );
              })
            :
            props.trafficSignTypes.map(type => {
              if (props.selectedCategory !== '' && type.get('category') !== parseInt(props.selectedCategory, 10)) {
                return null;
              }
              return (
                <img key={type.get('name')} className='traffic-sign-select-icon'
                  alt={type.get('name')} src={'images/' + type.get('icon')}
                  onClick={!props.makeCombineTrafficSign ?
                    props.addTrafficSignToCategory.bind(null, type)
                    : props.combineTrafficSignInCategory.bind(null, type)} />
              );
            })
          }
        </div>
        Valitut merkit:
        <div ref={element => newCategoryCurrent = element} id='current-new-category'>
          {
            props.newCategoryTrafficSigns.map((types, index) => {
              return (
                <div key={index} className='traffic-sign-collection'>
                  {
                    types.map((type, index) => {
                      return (
                        <img key={type.name} className={'traffic-sign-select-icon traffic-sign-collection-item' +
                          (index > 0 ? ' another' : '')}
                          alt={type.name} src={'images/' + type.icon}
                          onClick={props.removeTrafficSignFromCategory.bind(null, type)} />
                      );
                    })
                  }
                </div>
              );
            })
          }
        </div>
        <button id='accept-button' onClick={props.createNewCategory}>
          Luo kategoria
        </button>
      </div>
    </div>
  );
}

const ChooseDirection = props => {
  if (!props.show) return null;

  return (
    <div onClick={props.toggle} className='modal'>
      <div onClick={e => e.stopPropagation()} id='modal-area-direction-selection'>
        <div className='close' onClick={props.toggle} />
        {props.loading ?
          <div className='loader center' />
          :
          <div>
            <h4>Valitse vaikuttava suunta</h4>
            {!props.freeDirectionRotation && props.foundRoad ?
              <div>
                <span>
                  Löydetty tie: {props.foundRoad}
                  {props.lockedRoad == null ?
                    <span className='link' onClick={props.lockRoad}> Lukitse tie</span>
                    :
                    <span className='link' onClick={props.clearLockedRoad}> Poista lukitus ({props.lockedRoad})</span>
                  }
                </span>
              </div>
              :
              null
            }
            {props.freeDirectionRotation && props.lockedRoad != null ?
              <span className='link' onClick={props.clearLockedRoad}>Poista lukitus ({props.lockedRoad})</span>
              :
              null
            }
            <br />
            {props.freeDirectionRotation ?
              <img id='background-image' alt='' src='images/compass-rose.png' />
              :
              null
            }
            <div id='direction-select' style={{ transform: 'rotate(' + props.rotation + 'deg)' }}>
              {props.freeDirectionRotation ?
                <div id='rotate-object' onTouchStart={props.startRotateDirection}
                  onMouseDown={props.startRotateDirection} />
                :
                null
              }
              <div className='direction' onClick={props.selectDirection.bind(null, 2)}>
                <i className='fa fa-chevron-down' /> {props.freeDirectionRotation ? null : 2}
                {props.selectedType ?
                  <div className='image-container'>
                    <img className='traffic-sign-select-icon flip'
                      alt='2' src={'images/' + (props.selectedType[0] ? props.selectedType[0].icon : props.selectedType.get('icon'))} />
                  </div>
                  : null
                }
              </div>
              <div className='direction' onClick={props.selectDirection.bind(null, 1)}>
                {props.selectedType ?
                  <div className='image-container'>
                    <img className='traffic-sign-select-icon'
                      alt='1' src={'images/' + (props.selectedType[0] ? props.selectedType[0].icon : props.selectedType.get('icon'))} />
                  </div>
                  : null
                }
                <i className='fa fa-chevron-up' /> {props.freeDirectionRotation ? null : 1}
              </div>
            </div>
            {props.freeDirectionRotation ?
              <button onClick={props.unSetFreeRotation}>
                Hae tie automaattisesti
              </button>
              :
              <button onClick={props.setFreeRotation}>
                Päätä itse suunta
              </button>
            }
          </div>
        }
      </div>
    </div>
  );
}

const TrafficSignPage = (properties) => {
  const props = properties;

  let trafficSignPage;
  let socket;
  const dimensions = useRef({});
  let watchID;
  let map;
  let removingTrafficSignId;

  const [loadingTypes, setLoadingTypes] = useState(false);
  const [yourLatitude, setYourLatitude] = useState(null);
  const [yourLongitude, setYourLongitude] = useState(null);
  const [markerLatitude, setMarkerLatitude] = useState(null);
  const [markerLongitude, setMarkerLongitude] = useState(null);
  const [lockedLocation, setLockedLocation] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [ownCategories, setOwnCategories] = useState([]);
  const [selectedDirection, setSelectedDirection] = useState(null);
  const [chooseDirectionRotation, setChooseDirectionRotation] = useState(0);
  const [freeDirectionRotation, setFreeDirectionRotation] = useState(false);
  const [markBothSide, setMarkBothSide] = useState(!(localStorage.markBothSide === 'false'));
  const [newCategoryTrafficSigns, setNewCategoryTrafficSigns] = useState([]);
  const [lockedRoad, setLockedRoad] = useState(null);
  const [goToTrafficSign, setGoToTrafficSign] = useState(null);
  const [mapTilesUrl, setMapTilesUrl] = useState('');
  const [mapTilesAttribution, setMapTilesAttribution] = useState('');
  const [maxZoom, setMaxZoom] = useState(16);
  const [draggingDirectionRotation, setDraggingDirectionRotation] = useState(false);
  const [loadingTrafficSigns, setLoadingTrafficSigns] = useState(false);
  const [selectedTrafficSign, setSelectedTrafficSign] = useState(null);
  const [copyingType, setCopyingType] = useState(null);
  const [draggingTrafficSign, setDraggingTrafficSign] = useState(null);
  const [oldMarkerLatitude, setOldMarkerLatitude] = useState(null);
  const [oldMarkerLongitude, setOldMarkerLongitude] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [foundRoad, setFoundRoad] = useState(null);
  const [foundRoadPart, setFoundRoadPart] = useState(null);
  const [dimensionsReady, setDimensionsReady] = useState(null);
  const [foundRoadDistance, setFoundRoadDistance] = useState(null);
  const [loadingRoad, setLoadingRoad] = useState(false);
  const [preventDirectionToggle, setPreventDirectionToggle] = useState(false);
  const [chooseDirection, setChooseDirection] = useState(false);
  const [previousDirectionRotation, setPreviousDirectionRotation] = useState(null);
  const [makeCombineTrafficSign, setMakeCombineTrafficSign] = useState(null);
  const [showNewCategory, setShowNewCategory] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState(null);
  const [direction, setDirection] = useState(null);
  const [editingTrafficSign, setEditingTrafficSign] = useState(null);

  useEffect(() => {
    if (localStorage['login'] == null) {
      return;
    }

    setAllImageDimensions();

    if (socket == null) {
      socket = Socket('/data/trafficsign');
      socket.onmessage = function (e) {
        const data = JSON.parse(e.data);
        console.log(data);
        if (data['operation'] === 'create') {
          props.addTrafficSign(data.model);
        }
        else if (data['operation'] === 'update') {
          props.changeTrafficSign(data.model);
        }
        else if (data['operation'] === 'delete') {
          props.removeTrafficSign(data.model);
        }
      }
    }

    if (!navigator.geolocation) {
      props.showMessage('Varoitus',
        'Paikan haku ei ole tuettu tällä selaimella eli et voi saada sinun sijaintia kartalle', 'Warning');
      return;
    }

    watchID = navigator.geolocation.watchPosition(position => {
      setYourLatitude(position.coords.latitude);
      setYourLongitude(position.coords.longitude);
    }, error => {
      console.log(error.code);
    }, { enableHighAccuracy: true });

    if (localStorage['savedCategories'] != null) {
      setOwnCategories(JSON.parse(localStorage['savedCategories']));
    }
    return () => {
      if (socket != null) socket.close();
      if (watchID != null) {
        navigator.geolocation.clearWatch(watchID);
      }
    };
  }, []);

  useEffect(() => {
    if (props.selectedConstructionSite != null) {
      getTrafficSigns(props.selectedConstructionSite);
    }
  }, [props.selectedConstructionSite]);

  const getTrafficSigns = async (site, sentSavedTrafficSigns = false) => {
    if (site == null) {
      props.addTrafficSigns([]);
      return;
    }

    const id = site.get('id')

    setLoadingTrafficSigns(true);

    let trafficSigns = [];

    try {
      trafficSigns = await fetch('/trafficsigns?siteId=' + id);
      if (!sentSavedTrafficSigns &&
        typeof (Storage) !== 'undefined' &&
        localStorage['savedTrafficSigns'] != null &&
        JSON.parse(localStorage['savedTrafficSigns']).length !== 0) {
        sendSavedTrafficSigns();
        return;
      }
      for (let index in trafficSigns) {
        let trafficSign = trafficSigns[index];
        if (trafficSign.image !== 'null') {
          const type = 'image/' + trafficSign.image.split('.')[1];
          try {
            const response = await fetch(trafficSign.image, 'GET', null, type);
            const fileData = await loadImage(response);
            trafficSign.image = fileData;
          } catch (error) {
            console.log(error);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }

    if (typeof (Storage) !== 'undefined' &&
      localStorage['savedTrafficSigns'] != null) {
      const savedTrafficSigns = JSON.parse(localStorage['savedTrafficSigns']);
      trafficSigns = trafficSigns.concat(savedTrafficSigns.filter(trafficSign => trafficSign.constructionSiteId === id));
    }

    props.addTrafficSigns(trafficSigns);

    setLoadingTrafficSigns(false);
  }

  const sendSavedTrafficSigns = () => {
    let trafficSigns = JSON.parse(localStorage['savedTrafficSigns']);
    let newTrafficSigns = trafficSigns.slice();
    let index = 0;

    trafficSigns.forEach(async trafficSign => {
      delete trafficSign.id;

      const converted = toETRSTM35FIN(trafficSign.latitude, trafficSign.longitude);
      const roadData = await getRoadData(converted.y, converted.x);
      trafficSign.road_number = roadData.road || null;
      trafficSign.road_part = roadData.part || null;
      trafficSign.road_distance = roadData.distance || null;
      await fetch('/trafficsigns/', 'POST', trafficSign);
      newTrafficSigns.splice(newTrafficSigns.findIndex(sign => sign['id'] === trafficSign['id']), 1);

      index++;

      if (index === trafficSigns.length) {
        localStorage['savedTrafficSigns'] = JSON.stringify(newTrafficSigns);
        getTrafficSigns(props.selectedConstructionSite, true);
      }
    });
  }

  const setMarker = (event) => {
    setMarkerLatitude(event.latlng.lat);
    setMarkerLongitude(event.latlng.lng);
    setSelectedTrafficSign(null);
  }

  useEffect(() => {
    if (copyingType != null) {
      newTrafficSign(copyingType);
      setCopyingType(null);
    } else if (draggingTrafficSign != null) {
      toggleChooseDirection();
    }
  }, [markerLatitude, markerLongitude]);

  const clearMarker = () => {
    setMarkerLatitude(null);
    setMarkerLongitude(null);
    setSelectedTrafficSign(null);
    map.closePopup();
  }

  const setMarkerAfterDrag = (event) => {
    setMarkerLatitude(event.target._latlng.lat);
    setMarkerLongitude(event.target._latlng.lng);
  }

  const setTrafficSignAfterDrag = async (trafficSign, event) => {
    const type = props.trafficSignTypes.find(type => type.get('name') ===
      trafficSign.get('signs').get(0).get('name'));
    setOldMarkerLatitude(markerLatitude);
    setOldMarkerLongitude(markerLongitude);
    setMarkerLatitude(event.target._latlng.lat);
    setMarkerLongitude(event.target._latlng.lng);
    setDraggingTrafficSign(trafficSign);
    setSelectedType(type);
    toggleChooseDirection();
  }

  const changeTrafficSignAfterDrag = () => {
    const movedTrafficSign = {
      id: draggingTrafficSign.get('id'),
      time: draggingTrafficSign.get('time'),
      constructionSiteId: draggingTrafficSign.get('constructionSiteId'),
      signs: draggingTrafficSign.get('signs'),
      latitude: markerLatitude,
      longitude: markerLongitude,
      road_number: foundRoad ? foundRoad : null,
      road_part: foundRoadPart ? foundRoadPart : null,
      road_distance: foundRoadPart ? foundRoadPart : null,
      direction: selectedDirection,
      angle: chooseDirectionRotation
    };
    updateTrafficSign(movedTrafficSign);
  }

  const newTrafficSign = async (type) => {
    if (props.selectedConstructionSite == null) {
      props.showNotice('Valitse ensin haluttu kohde', 'Warning');
      return;
    }

    let latitude = markerLatitude;
    let longitude = markerLongitude;

    if (selectedTrafficSign == null && latitude == null) {
      if (yourLatitude == null) {
        props.showNotice('Koska sijaintiasi ei ole saatavana. Valitse kartalta ensin paikka, mihin merkki laitetaan.', 'Warning');
        return;
      }
      latitude = yourLatitude;
      longitude = yourLongitude;
    }

    let sameLocatedTrafficSign = null;

    if (selectedTrafficSign != null) {
      sameLocatedTrafficSign = selectedTrafficSign;
    }
    else {
      sameLocatedTrafficSign = props.trafficSigns.find(
        sign => sign.get('latitude') === latitude && sign.get('longitude') === longitude);
    }

    if (sameLocatedTrafficSign != null && sameLocatedTrafficSign.get('erected') != null) {
      return;
    }

    // We have already traffic sign in this spot, so we update it
    if (sameLocatedTrafficSign != null) {
      let existingType = false;

      if (type.get) {
        existingType = sameLocatedTrafficSign.get('signs').find(t => t.get('name') === type.get('name'));
      }
      else {
        for (let i in type) {
          if (sameLocatedTrafficSign.get('signs').find(t => t.get('name') === type[i].name)) {
            existingType = true;
            break;
          }
        }
      }

      if (existingType) {
        props.showNotice('Sama merkki on jo tässä', 'Warning');
        return;
      }

      if (type.get) {
        if (type.get('customText')) {
          const text = prompt('Teksti kylttiin (' + type.get('name') + '):', '');
          if (text == null) return;
          type = type.set('text', text);
        }
      }
      else {
        for (let i in type) {
          if (type[i].customText) {
            const text = prompt('Teksti kylttiin (' + type[i].name + '):', '');
            if (text == null) return;
            type[i].text = text;
          }
        }
      }

      let types = sameLocatedTrafficSign.get('signs');

      if (type.get) {
        types = types.push(type);
      }
      else {
        types = types.concat(type);
      }

      const trafficSign = {
        id: sameLocatedTrafficSign.get('id'),
        time: sameLocatedTrafficSign.get('time'),
        constructionSiteId: sameLocatedTrafficSign.get('constructionSiteId'),
        signs: types,
        latitude: sameLocatedTrafficSign.get('latitude'),
        longitude: sameLocatedTrafficSign.get('longitude')
      };

      if (selectedTrafficSign != null) {
        setSelectedTrafficSign(fromJS(trafficSign));
      }

      updateTrafficSign(trafficSign);
      return;
    }

    if (selectedDirection == null) {
      setSelectedType(type);
      toggleChooseDirection();
      return;
    }

    if (type.get) {
      if (type.get('customText')) {
        const text = prompt('Teksti kylttiin (' + type.get('name') + '):', '');
        if (text == null) return;
        type = type.set('text', text);
      }
    }
    else {
      for (let i in type) {
        if (type[i].customText) {
          const text = prompt('Teksti kylttiin (' + type[i].name + '):', '');
          if (text == null) return;
          type[i].text = text;
        }
      }
    }

    const time = new Date();

    let types = [];

    if (type.get) {
      types.push({
        name: type.get('name'),
        category: type.get('category'),
        icon: type.get('icon'),
        customText: type.get('customText'),
        customTextColor: type.get('customTextColor'),
        text: type.get('text'),
      })
    }
    else {
      types = type;
    }

    const converted = toETRSTM35FIN(latitude, longitude);
    const roadData = await getRoadData(converted.y, converted.x);

    let angle = chooseDirectionRotation;

    if (angle != null && direction === 1) {
      angle += 180;
    }

    let trafficSign = {
      time: time.toISOString(),
      constructionSiteId: props.selectedConstructionSite.get('id'),
      signs: types,
      latitude: latitude,
      longitude: longitude,
      road_number: roadData.road || null,
      road_part: roadData.part || null,
      road_distance: roadData.distance || null,
      direction: selectedDirection,
      angle: angle,
      both_side: markBothSide
    };

    try {
      trafficSign = await fetch('/trafficsigns', 'POST', trafficSign);
    } catch (error) {
      if (localStorage['savedTrafficSigns'] == null) {
        localStorage['savedTrafficSigns'] = JSON.stringify([]);
      }
      let trafficSigns = JSON.parse(localStorage['savedTrafficSigns']);
      trafficSign.id = Date.now();
      trafficSigns.push(trafficSign);
      localStorage['savedTrafficSigns'] = JSON.stringify(trafficSigns);
    }

    props.addTrafficSign(trafficSign);

    setGoToTrafficSign(trafficSign.id);
  }

  useEffect(() => {
    setGoToTrafficSign(null);
  }, [goToTrafficSign]);

  const updateTrafficSign = async (trafficSign) => {
    try {
      trafficSign = await fetch('/trafficsigns/' + trafficSign.id, 'PATCH', trafficSign);
    } catch (error) {
      if (localStorage['savedTrafficSigns'] != null) {
        let trafficSigns = JSON.parse(localStorage['savedTrafficSigns']);

        let editingTrafficSignIndex = trafficSigns.findIndex(sign => sign.id === trafficSign.id);

        if (editingTrafficSignIndex !== -1) {
          trafficSigns.splice(editingTrafficSignIndex, 1);
          trafficSigns.push(trafficSign);
          localStorage['savedTrafficSigns'] = JSON.stringify(trafficSigns);
          props.changeTrafficSign(trafficSign);
          return;
        }
      }
      if (localStorage['updatedTrafficSigns'] == null) {
        localStorage['updatedTrafficSigns'] = JSON.stringify([]);
      }

      let trafficSigns = JSON.parse(localStorage['updatedTrafficSigns']);
      trafficSigns.push(trafficSign);
      localStorage['updatedTrafficSigns'] = JSON.stringify(trafficSigns);
    }

    setSelectedTrafficSign(fromJS(trafficSign));

    if (trafficSign.image !== 'null') {
      const type = 'image/' + trafficSign.image.split('.')[1];
      const response = await fetch(trafficSign.image, 'GET', null, type);
      const fileData = await loadImage(response);
      trafficSign.image = fileData;
    }

    props.changeTrafficSign(trafficSign);

    setGoToTrafficSign(trafficSign.id);
  }

  useEffect(() => {
    setGoToTrafficSign(null);
  }, [goToTrafficSign]);

  const toggleLockedLocation = () => {
    setLockedLocation(!lockedLocation);
  }

  const selectTrafficSign = (trafficSign) => {
    setSelectedTrafficSign(trafficSign);
    setMarkerLatitude(null);
    setMarkerLongitude(null);
  }

  const changeCategory = (event) => {
    setSelectedCategory(event.target.value);
  }

  const getImageIcon = (name) => {
    const foundType = props.trafficSignTypes.find(type => type.get('name') === name);

    if (foundType != null) {
      return props.trafficSignTypes.find(type => type.get('name') === name).get('icon');
    }

    return null;
  }

  const setImageDimensions = (file) => {
    return new Promise(function (resolved, rejected) {
      let image = new Image();
      image.onload = () => {
        resolved({ width: image.width, height: image.height });
      };
      image.src = file;
    })
  }

  const setAllImageDimensions = async () => {
    for (let index = 0; index < props.trafficSignTypes.size; index++) {
      const type = props.trafficSignTypes.get(index);
      dimensions[type.get('icon')] = await setImageDimensions('images/' + type.get('icon'));
      if (index + 1 === props.trafficSignTypes.size) {
        setDimensionsReady(true);
      }
    }
  }

  const getImageDimensions = (file) => {
    return dimensions[file];
  }

  const erectTrafficSign = (trafficSign) => {
    const time = new Date();

    const editedTrafficSign = {
      id: trafficSign.get('id'),
      time: trafficSign.get('time'),
      erected: time.toISOString(),
      constructionSiteId: trafficSign.get('constructionSiteId'),
      signs: trafficSign.get('signs'),
      latitude: trafficSign.get('latitude'),
      longitude: trafficSign.get('longitude'),
      road_number: trafficSign.get('road_number'),
      road_part: trafficSign.get('road_part'),
      road_distance: trafficSign.get('road_distance'),
      direction: trafficSign.get('direction'),
      angle: trafficSign.get('angle')
    };

    updateTrafficSign(editedTrafficSign);
  }

  const confirmErectAllTrafficSigns = () => {
    props.showConfirm('Pystytetäänkö kaikki pystymättömät liikennemerkit?', erectAllTrafficSigns);
  }

  const erectAllTrafficSigns = () => {
    props.trafficSigns.forEach(trafficSign => {
      if (trafficSign.get('erected') == null) {
        erectTrafficSign(trafficSign);
      }
    });
  }

  const raiseTrafficSign = (trafficSign) => {
    const time = new Date();

    const editedTrafficSign = {
      id: trafficSign.get('id'),
      time: trafficSign.get('time'),
      erected: trafficSign.get('erected'),
      raised: time.toISOString(),
      constructionSiteId: trafficSign.get('constructionSiteId'),
      signs: trafficSign.get('signs'),
      latitude: trafficSign.get('latitude'),
      longitude: trafficSign.get('longitude'),
      road_number: trafficSign.get('road_number'),
      road_part: trafficSign.get('road_part'),
      road_distance: trafficSign.get('road_distance'),
      direction: trafficSign.get('direction'),
      angle: trafficSign.get('angle')
    };

    updateTrafficSign(editedTrafficSign);
  }

  const confirmRaiseAllTrafficSigns = () => {
    props.showConfirm('Poistetaanko kaikki pystytetyt liikennemerkit?', raiseAllTrafficSigns);
  }

  const raiseAllTrafficSigns = () => {
    props.trafficSigns.forEach(trafficSign => {
      if (trafficSign.get('erected') != null &&
        trafficSign.get('raised') == null) {
        raiseTrafficSign(trafficSign);
      }
    });
  }

  const confirmRemoveTrafficSign = (id) => {
    removingTrafficSignId = id;
    props.showConfirm('Poistetaanko liikennemerkki kokonaan järjestelmästä? (Ei jätä mitään jälkeä merkistä)', removeTrafficSign);
  }

  const removeTrafficSign = async () => {
    const id = removingTrafficSignId;
    removingTrafficSignId = null;

    try {
      await fetch('/trafficsigns/' + id, 'DELETE');
      props.removeTrafficSign(id);
    } catch (error) {
      if (localStorage['savedTrafficSigns'] != null) {
        let trafficSigns = JSON.parse(localStorage['savedTrafficSigns']);
        if (trafficSigns.length !== 0) {
          const index = trafficSigns.find(sign => sign.id === id);
          if (index !== -1) {
            trafficSigns.splice(index, 1);
            localStorage['savedTrafficSigns'] = JSON.stringify(trafficSigns);
            props.removeTrafficSign(id);
            return;
          }
        }
      }
      props.showMessage('Virhe', 'Liikennemerkin poisto epäonnistui', 'Error');
    }
  }

  const confirmRemoveLatestSignType = async (trafficSign) => {
    setEditingTrafficSign(trafficSign);
    props.showConfirm('Poistetaanko liikennemerkistä viimeisin merkki?', removeLatestSignType);
  }

  const removeLatestSignType = async () => {
    const trafficSign = editingTrafficSign;
    let newType = trafficSign.get('signs');
    newType = newType.delete(newType.size - 1);

    const editedTrafficSign = {
      id: trafficSign.get('id'),
      time: trafficSign.get('time'),
      erected: trafficSign.get('erected'),
      raised: trafficSign.get('raised'),
      constructionSiteId: trafficSign.get('constructionSiteId'),
      signs: newType,
      latitude: trafficSign.get('latitude'),
      longitude: trafficSign.get('longitude'),
      road_number: trafficSign.get('road_number'),
      road_part: trafficSign.get('road_part'),
      road_distance: trafficSign.get('road_distance'),
      direction: trafficSign.get('direction'),
      angle: trafficSign.get('angle')
    };

    updateTrafficSign(editedTrafficSign);
  }

  const toggleNewCategory = () => {
    setShowNewCategory(!showNewCategory);
    setNewCategoryTrafficSigns([]);
    setNewCategoryName('');
  }

  const createNewCategory = () => {
    if (newCategoryName === '') {
      props.showNotice('Kategorialle ei ole annettu nimeä', 'Warning');
      return;
    }

    const exist = ownCategories.find(category => category.name === newCategoryName);

    if (exist != null) {
      props.showNotice('Tämän niminen kategoria on jo olemassa', 'Warning');
      return;
    }

    if (newCategoryTrafficSigns.length === 0) {
      props.showNotice('Kategorialle ei ole yhtään merkkiä laitettu', 'Warning');
      return;
    }

    if (localStorage['savedCategories'] == null) {
      localStorage['savedCategories'] = JSON.stringify([]);
    }

    let categories = JSON.parse(localStorage['savedCategories']);
    categories.push({
      name: newCategoryName,
      trafficSigns: newCategoryTrafficSigns
    });
    localStorage['savedCategories'] = JSON.stringify(categories);
    setOwnCategories(categories);
    setShowNewCategory(false);
    props.showNotice('Kategoria luotu', 'Ok');
  }

  const confirmDeleteCategory = () => {
    props.showConfirm('Poistetaanko kategoria ' + selectedCategory + '?', deleteCategory);
  }

  const deleteCategory = async () => {
    timer(0).then(() => {
      let categories = JSON.parse(localStorage['savedCategories']);
      const index = categories.findIndex(category => category.name === selectedCategory);
      categories.splice(index, 1);
      localStorage['savedCategories'] = JSON.stringify(categories);
      setOwnCategories(categories);
      setSelectedCategory('');
      props.showNotice('Kategoria poistettu', 'Ok');
    });
  }

  const addTrafficSignToCategory = (type) => {
    let newType;

    if (type.get) {
      newType = [{
        name: type.get('name'),
        category: type.get('category'),
        icon: type.get('icon'),
        customText: type.get('customText')
      }];
    }
    else {
      newType = type;
    }

    let newCategoryTrafficSigns2 = newCategoryTrafficSigns;
    newCategoryTrafficSigns2.push(newType);

    setNewCategoryTrafficSigns(newCategoryTrafficSigns2);
  }

  const combineTrafficSignInCategory = (type) => {
    const lastIndex = newCategoryTrafficSigns.length - 1;

    if (lastIndex === -1) {
      addTrafficSignToCategory(type);
      return;
    }

    let lastTrafficSign = newCategoryTrafficSigns[lastIndex];

    let newType;

    if (type.get) {
      if (lastTrafficSign.find(sign => sign.name === type.get('name'))) {
        props.showNotice('Kyseinen merkki on jo ryhmässä', 'Warning');
        return;
      }

      newType = {
        name: type.get('name'),
        category: type.get('category'),
        icon: type.get('icon'),
        customText: type.get('customText')
      }
    }
    else {
      for (let i in type) {
        if (lastTrafficSign.find(sign => sign.name === type[i].name)) {
          props.showNotice('Kyseinen merkki on jo ryhmässä', 'Warning');
          return;
        }
      }
      newType = type;
    }

    lastTrafficSign = lastTrafficSign.concat(newType);
    let category = newCategoryTrafficSigns;
    category[lastIndex] = lastTrafficSign;

    setNewCategoryTrafficSigns(category);
  }

  useEffect(() => {
    if (newCategoryCurrent) {
      const scrollHeight = newCategoryCurrent.scrollHeight;
      const height = newCategoryCurrent.clientHeight;
      const maxScrollTop = scrollHeight - height;
      newCategoryCurrent.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  }, [newCategoryTrafficSigns]);

  const removeTrafficSignFromCategory = (type) => {
    let trafficSigns = newCategoryTrafficSigns;
    const index = trafficSigns.findIndex(trafficSign => trafficSign.name === type.name);
    trafficSigns.splice(index, 1);

    setNewCategoryTrafficSigns(trafficSigns);
  }

  const changeNewCategoryName = (event) => {
    setNewCategoryName(event.target.value);
  }

  const copyTrafficSign = (type) => {
    let newType = [];

    for (let i = 0; i < type.size; i++) {
      newType.push({
        name: type.get(i).get('name'),
        category: type.get(i).get('category'),
        icon: getImageIcon(type.get(i).get('name')),
        customText: type.get(i).get('customText')
      });
    }

    setCopyingType(newType);
    props.showNotice('Paina kartalta kohta mihin haluat kopion menevän', 'Ok');
  }

  const moveTrafficSign = (trafficSign) => {
    const type = props.trafficSignTypes.find(type => type.get('name') ===
      trafficSign.get('signs').get(0).get('name'));
    setDraggingTrafficSign(trafficSign);
    setSelectedType(type);
    props.showNotice('Paina kartalta kohta mihin haluat merkin', 'Ok');
    map.closePopup();
  }

  const erectAgainTrafficSign = async (trafficSign) => {
    const time = new Date();

    let newTrafficSign = {
      time: time.toISOString(),
      constructionSiteId: trafficSign.get('constructionSiteId'),
      signs: trafficSign.get('signs'),
      latitude: trafficSign.get('latitude'),
      longitude: trafficSign.get('longitude'),
      erected: time.toISOString(),
      road_number: trafficSign.get('road_number'),
      road_part: trafficSign.get('road_part'),
      road_distance: trafficSign.get('road_distance'),
      direction: trafficSign.get('direction'),
      angle: trafficSign.get('angle')
    }

    try {
      newTrafficSign = await fetch('/trafficsigns', 'POST', newTrafficSign);
    } catch (error) {
      if (localStorage['savedTrafficSigns'] == null) {
        localStorage['savedTrafficSigns'] = JSON.stringify([]);
      }
      let trafficSigns = JSON.parse(localStorage['savedTrafficSigns']);
      newTrafficSign.id = Date.now();
      trafficSigns.push(newTrafficSign);
      localStorage['savedTrafficSigns'] = JSON.stringify(trafficSigns);
    }

    props.addTrafficSign(newTrafficSign);

    map.closePopup();
  }

  const setRoadRotation = async () => {
    setLoadingRoad(true);
    const converted = toETRSTM35FIN(markerLatitude || yourLatitude,
      markerLongitude || yourLongitude);

    let road = null;

    if (lockedRoad != null) {
      road = lockedRoad;
    }

    let degree = 0;

    const roadData = await getRoadData(converted.y, converted.x, 10, road);

    if (roadData != null) {
      let x = roadData.x;
      let y = roadData.y;

      const distanceX = x - converted.x;
      const distanceY = y - converted.y;
      const allowDistance = 20;

      if (Math.sqrt(distanceX * distanceX + distanceY * distanceY) <= allowDistance) {
        const roadNumber = roadData.road;
        const roadPart = roadData.part;
        const roadDistance = roadData.distance;

        const distanceBetween = 15;
        let anotherPoint;

        let swap = false;

        if (roadDistance < distanceBetween) {
          anotherPoint = roadDistance + distanceBetween;
          swap = true;
        }
        else {
          anotherPoint = roadDistance - distanceBetween;
        }

        const secondRoadData = await getRoadCoordinates(roadNumber, roadPart, anotherPoint)

        let x2 = secondRoadData.x;
        let y2 = secondRoadData.y;

        if (swap) {
          [x, x2] = [x2, x];
          [y, y2] = [y2, y];
        }

        degree = -(Math.atan2(y - y2, x - x2) * (180 / Math.PI)) - 270;
      }
    }

    const foundRoad = roadData ? roadData.road : null;

    if (foundRoad == null) {
      setFreeRotation();
      props.showNotice('Tie tietoja ei löytynyt', 'Warning');
    }

    const foundRoadPart = roadData ? roadData.part : null;
    const foundRoadDistance = roadData ? roadData.distance : null;

    setChooseDirectionRotation(degree);
    setFoundRoad(foundRoad);
    setFoundRoadPart(foundRoadPart);
    setFoundRoadDistance(foundRoadDistance);
    setLoadingRoad(false);
  }

  const toggleChooseDirection = async () => {
    if (preventDirectionToggle) {
      setPreventDirectionToggle(false);
      return;
    }
    if (!chooseDirection) {
      await setRoadRotation();
    }
    setChooseDirection(!chooseDirection);
    setFreeDirectionRotation(false);
    if (draggingTrafficSign && chooseDirection) {
      setDraggingTrafficSign(false);
      setMarkerLatitude(oldMarkerLatitude);
      setMarkerLongitude(oldMarkerLongitude);
    }
  }

  const selectDirection = async (direction) => {
    setSelectedDirection(direction);
    setChooseDirection(false);
  }

  useEffect(() => {
    async function callNewTrafficSign() {
      await newTrafficSign(selectedType);
    }

    if (draggingTrafficSign) {
      changeTrafficSignAfterDrag();
      setDraggingTrafficSign(null);
      setMarkerLatitude(oldMarkerLatitude);
      setMarkerLongitude(oldMarkerLongitude);
    } else if (selectedDirection) {
      callNewTrafficSign();
    }

    setSelectedDirection(null);
  }, [selectedDirection]);

  const setFreeRotation = () => {
    setPreviousDirectionRotation(chooseDirectionRotation);
    setChooseDirectionRotation(0);
    setFreeDirectionRotation(true);
  }

  const unSetFreeRotation = async () => {
    await setRoadRotation();
    setChooseDirectionRotation(previousDirectionRotation);
    setFreeDirectionRotation(false);
  }

  const startRotateDirection = (e) => {
    const page = trafficSignPage;
    const touchMove = function (e) {
      const screenWidth = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;
      const screenHeight = window.innerHeight
        || document.documentElement.clientHeight
        || document.body.clientHeight;
      const touch = e.targetTouches[0];
      const x = touch.screenX;
      const y = touch.screenY;
      const degree = (Math.atan2(y - screenHeight / 2, x - screenWidth / 2) * (180 / Math.PI)) - 270;
      setChooseDirectionRotation(degree);
    }.bind(this);

    const touchEnd = function (e) {
      page.removeEventListener('touchmove', touchMove);
      page.removeEventListener('touchend', touchEnd);
      setDraggingDirectionRotation(false);
      setPreventDirectionToggle(true);
    }.bind(this);
    page.addEventListener('touchmove', touchMove);
    page.addEventListener('touchend', touchEnd);

    const mouseMove = function (e) {
      const screenWidth = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;
      const screenHeight = window.innerHeight
        || document.documentElement.clientHeight
        || document.body.clientHeight;
      const x = e.clientX;
      const y = e.clientY;
      const degree = (Math.atan2(y - screenHeight / 2, x - screenWidth / 2) * (180 / Math.PI)) - 270;
      setChooseDirectionRotation(degree);
    }.bind(this);

    const mouseUp = function (e) {
      page.removeEventListener('mousemove', mouseMove);
      page.removeEventListener('mouseup', mouseUp);
      setDraggingDirectionRotation(false);
      setPreventDirectionToggle(true);
    }.bind(this);
    page.addEventListener('mousemove', mouseMove);
    page.addEventListener('mouseup', mouseUp);

    setDraggingDirectionRotation(true);
  }

  const loadImage = (blod) => {
    return new Promise(function (resolved, rejected) {
      const reader = new FileReader();
      reader.readAsDataURL(blod);
      reader.onload = () => {
        const base64data = reader.result;
        resolved(base64data);
      };
    })
  }

  const setImageToTrafficSign = async (trafficSign, event) => {
    const file = event.target.files[0];
    const fileType = file.type;
    const blob = file.slice(0, file.size, fileType);
    const formData = new FormData();
    formData.append('file', blob, file.name);
    const path = 'nevisign/' + trafficSign.get('id') + '/';
    try {
      await fetch(path, 'DELETE', null, fileType);
    } catch (error) {

    }
    await fetch(path, 'POST', formData, fileType);

    const editedTrafficSign = {
      id: trafficSign.get('id'),
      image: path + file.name
    }

    updateTrafficSign(editedTrafficSign);
  }

  const toggleMarkBothSide = () => {
    const value = !markBothSide;
    setMarkBothSide(value);
    localStorage.markBothSide = value;
  }

  const toggleMakeCombineTrafficSign = () => {
    if (!makeCombineTrafficSign) {
      props.showNotice('Nyt luot yhdistelmää', 'Warning');
    }
    else {
      props.showNotice('Yhdistelmän luonti lopetettu', 'Warning');
    }

    setMakeCombineTrafficSign(!makeCombineTrafficSign);
  }

  const lockRoad = async () => {
    const road = integerValue(prompt('Anna haluttu tie', ''), 0);

    if (road === 0) {
      return;
    }

    setLockedRoad(road);
    await this.setRoadRotation();
  }

  useEffect(() => {
    setGoToTrafficSign(null);
  }, [goToTrafficSign]);

  const clearLockedRoad = async () => {
    setLockedRoad(null);
    await this.setRoadRotation();
  }

  const showPopup = (ref, pos) => {
    if (ref == null) return;
    ref.openPopup();
    const locationWithOffset = calculateOffsetCoordinates(pos[0], pos[1], 0, 90);
    map.setView([locationWithOffset.latitude, locationWithOffset.longitude], 17);
  }

  const calculateOffsetCoordinates = (latitude, longitude, offsetMetresX, offsetMetresY) => {
    const R = 6371e3; // metres
    const newLatitude = latitude + (offsetMetresY / R) * (180 / Math.PI);
    const newLongitude = longitude + (offsetMetresX / R) * (180 / Math.PI) / Math.cos(latitude * Math.PI / 180);
    return { latitude: newLatitude, longitude: newLongitude };
  }

  function MapControl() {
    map = useMap();

    useMapEvent('click', (event) => {
      setMarker(event);
    });

    const mapCenter = map.getCenter();
    const converted = toETRSTM35FIN(mapCenter.lat, mapCenter.lng);
    const mapTiles = MapTiles(converted.x, converted.y);

    if (mapTilesUrl !== mapTiles.url) {
      setMapTilesUrl(mapTiles.url);
      setMapTilesAttribution(mapTiles.attribution);
      setMaxZoom(mapTiles.maxZoom);
    }

    if (
      (yourLatitude !== props.yourLatitude ||
        yourLongitude !== props.yourLongitude ||
        lockedLocation !== props.lockedLocation) &&
      props.lockedLocation &&
      props.yourLatitude != null) {
      map.setView([props.yourLatitude, props.yourLongitude], 17);
    }
  }

  useEffect(() => {
    if (map != null && (lockedLocation && yourLatitude != null)) {
      map.setView([yourLatitude, yourLongitude], 17);
    }
  }, [yourLatitude, yourLongitude, lockedLocation])  

  useEffect(() => {
    getTrafficSigns(props.selectedConstructionSite);
  }, [props.selectedConstructionSite])  

  const position = [64.1, 26.5];
  const zoom = 6;

  return (
    <div ref={element => trafficSignPage = element} className={draggingDirectionRotation ? 'dragging' : ''}>
      <TopContractAndSiteSelect store={props.store} />
      <MapContainer id="map" center={position} zoom={zoom} maxZoom={maxZoom}>
        <MapControl />
        <TileLayer
          url={mapTilesUrl}
          attribution={mapTilesAttribution}
          maxZoom={maxZoom} />
        {yourLatitude != null ? (
          <Marker position={[yourLatitude, yourLongitude]}
            icon={new L.Icon({
              iconUrl: 'images/your_location.gif', iconSize: [18, 43],
              iconAnchor: [9, 43],
              popupAnchor: [null, -43]
            })}
            zIndexOffset={-1000}>
            <Popup>
              <span>{Math.round(yourLatitude * 100000) / 100000}, {Math.round(yourLongitude * 100000) / 100000}</span>
            </Popup>
          </Marker>
        ) : null}
        {
          !loadingTrafficSigns || !loadingTypes ? null : (
            <div className="center loader"></div>
          )
        }
        <TrafficSigns trafficSigns={props.trafficSigns}
          selectedTrafficSign={selectedTrafficSign}
          getImageIcon={getImageIcon}
          getImageDimensions={getImageDimensions}
          selectTrafficSign={selectTrafficSign}
          dimensionsReady={dimensionsReady}
          erectTrafficSign={erectTrafficSign}
          raiseTrafficSign={raiseTrafficSign}
          removeTrafficSign={confirmRemoveTrafficSign}
          removeLatestSignType={confirmRemoveLatestSignType}
          setTrafficSignAfterDrag={setTrafficSignAfterDrag}
          copyTrafficSign={copyTrafficSign}
          erectAgainTrafficSign={erectAgainTrafficSign}
          setImageToTrafficSign={setImageToTrafficSign}
          moveTrafficSign={moveTrafficSign}
          goToTrafficSign={goToTrafficSign}
          showPopup={showPopup} />
        {markerLatitude != null ? (
          <Marker position={[markerLatitude, markerLongitude]}
            icon={new L.Icon({
              iconUrl: 'images/marker.gif', iconSize: [30, 30]
            })}
            draggable={true} onDragEnd={setMarkerAfterDrag}>
          </Marker>
        ) : null}
      </MapContainer>
      <div id='top-area'>
        <TrafficSignSelect trafficSignTypes={props.trafficSignTypes}
          newTrafficSign={newTrafficSign}
          changeCategory={changeCategory}
          selectedCategory={selectedCategory}
          ownCategories={ownCategories}
          newCategory={toggleNewCategory}
          deleteCategory={confirmDeleteCategory} />
        <button id='erect-all' onClick={confirmErectAllTrafficSigns}>
          Pystytä kaikki
        </button>
        <button id='raise-all' onClick={confirmRaiseAllTrafficSigns}>
          Poista kaikki
        </button>
        <label id='both-side-marking' className={markBothSide ? ' selected' : ''}>
          MERKITSE TIEN MOLEMMIN PUOLIN
          <input type='checkbox' checked={markBothSide}
            onChange={toggleMarkBothSide} />
        </label>
      </div>
      <label className={lockedLocation ? 'lock-location selected' : 'lock-location'}>
        SEURAA SIJAINTIASI
        <input type='checkbox' checked={lockedLocation}
          onChange={toggleLockedLocation} />
      </label>
      {markerLatitude != null || selectedTrafficSign != null ?
        <button id='clear-button' onClick={clearMarker}>
          Tyhjää valinta
        </button>
        :
        null
      }
      <NewCategory trafficSignTypes={props.trafficSignTypes}
        newTrafficSign={newTrafficSign}
        changeCategory={changeCategory}
        selectedCategory={selectedCategory}
        ownCategories={ownCategories}
        show={showNewCategory}
        toggle={toggleNewCategory}
        newCategoryTrafficSigns={newCategoryTrafficSigns}
        addTrafficSignToCategory={addTrafficSignToCategory}
        removeTrafficSignFromCategory={removeTrafficSignFromCategory}
        changeNewCategoryName={changeNewCategoryName}
        newCategoryName={newCategoryName}
        createNewCategory={createNewCategory}
        toggleMakeCombineTrafficSign={toggleMakeCombineTrafficSign}
        makeCombineTrafficSign={makeCombineTrafficSign}
        combineTrafficSignInCategory={combineTrafficSignInCategory} />
      <ChooseDirection show={chooseDirection} selectDirection={selectDirection}
        toggle={toggleChooseDirection} selectedType={selectedType}
        rotation={chooseDirectionRotation} freeDirectionRotation={freeDirectionRotation}
        setFreeRotation={setFreeRotation} unSetFreeRotation={unSetFreeRotation}
        startRotateDirection={startRotateDirection} foundRoad={foundRoad}
        loading={loadingRoad}
        lockRoad={lockRoad} clearLockedRoad={clearLockedRoad}
        lockedRoad={lockedRoad} />
    </div>
  );
};

export default connect(state => ({
  trafficSigns: state.trafficSign.get('trafficSigns'),
  trafficSignTypes: state.trafficSign.get('trafficSignTypes'),
  selectedConstructionSite: state.constructionSiteSelect.get('selectedConstructionSite'),
}), {
  addTrafficSign, changeTrafficSign, addTrafficSigns,
  removeTrafficSign, showNotice, showMessage, showConfirm
})(TrafficSignPage);
