export const addTrafficSign = trafficSign => ({
  type: 'ADD_TRAFFIC_SIGN',
  trafficSign
});

export const changeTrafficSign = trafficSign => ({
  type: 'CHANGE_TRAFFIC_SIGN',
  trafficSign
});

export const removeTrafficSign = trafficSignId => ({
  type: 'REMOVE_TRAFFIC_SIGN',
  trafficSignId
});

export const addTrafficSigns = trafficSigns => ({
  type: 'ADD_TRAFFIC_SIGNS', trafficSigns 
});
